<template>
  <div>
    <div class="card shadow">
      <div
        class="pt-4 pb-4 pe-4 ps-4"
        @keyup.enter="$emit('getDocumentiSocieta')"
      >
        <div class="row">
          <div class="col-sm-4 filter">
            <SelectInput
              :options="societa_tipo_documenti"
              name="societa_tipo_documenti"
              placeholder="Tipologia"
              :value="idTipoDocSoc"
              @changeSelect="setIdTipoDocSoc($event)"
            />
          </div>
          <div class="col-sm-4 filter">
            <Datepicker
              v-model="dataDaDocSoc"
              yearPicker
              placeholder="Data da"
              :format="format"
              :monthChangeOnScroll="false"
              :enableTimePicker="false"
              inputClassName="h-28"
              @update:model-value="setDataDaDocSoc"
              autoApply
            />
          </div>
          <div class="col-sm-4 filter">
            <Datepicker
              v-model="dataADocSoc"
              yearPicker
              placeholder="Data a"
              :format="format"
              :monthChangeOnScroll="false"
              :enableTimePicker="false"
              inputClassName="h-28"
              @update:model-value="setDataADocSoc"
              autoApply
            />
          </div>
        </div>
        <div class="text-center mt-5">
          <button
            type="button"
            data-bs-toggle=""
            data-bs-target=""
            aria-expanded="false"
            aria-controls=""
            class="badge rounded-pill bg-light text-gray-600 ms-4"
            @click="$emit('getDocumentiSocieta')"
            :disabled="!loaded"
          >
            Cerca <i class="bi bi-search fs-6 text-gray-600"> </i>
          </button>
          <span
            type="button"
            data-bs-toggle=""
            data-bs-target=""
            aria-expanded="false"
            aria-controls=""
            class="badge rounded-pill bg-light text-gray-600 ms-4"
            @click="$emit('resetFilters')"
          >
            <i class="bi bi-arrow-clockwise fs-6 text-gray-600"> </i
          ></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "vue3-date-time-picker/dist/main.css";
import { defineComponent, computed, getCurrentInstance, ref, watch } from "vue";
import Datepicker from "vue3-date-time-picker";
import { format } from "@/composables/formatDate";
import { useStore } from "vuex";
import SelectInput from "@/components/components-fit/utility/inputs/SelectInput.vue";

export default defineComponent({
  name: "filtri-doc-soc",
  emits: ["resetFilters", "getDocumentiSocieta"],
  components: {
    SelectInput,
    Datepicker,
  },
  props: {},
  setup(props, { emit }) {
    const store = useStore();

    const dataDaDocSoc = computed(() => store.getters.dataDaDocSoc);
    const dataADocSoc = computed(() => store.getters.dataADocSoc);
    const idTipoDocSoc = computed(() => store.getters.idTipoDocSoc);
    const permessiDocSoc = computed(() => store.getters.permessiDocSoc);

    const setDataDaDocSoc = (event) => {
      store.commit("setDataDaDocSoc", event);
    };
    const setDataADocSoc = (event) => {
      store.commit("setDataADocSoc", event);
    };
    const setIdTipoDocSoc = (event) => {
      store.commit("setIdTipoDocSoc", event);
    };
    const setPermessiDocSoc = (event) => {
      store.commit("setPermessiDocSoc", event);
    };

    const stagione = computed(() => store.getters.stagioneSelected);
    watch(stagione, () => {
      emit("getDocumentiSocieta");
    });

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;
    const societa_tipo_documenti = computed(() =>
      store.getters.getStateFromName("societa_tipo_documenti")
    );
    const keys = ref("std");
    if (!societa_tipo_documenti.value) {
      store.dispatch("setStoreData", {
        keys: { keys: keys.value },
        apiLink: globalApi.COMBOLIST_GET,
      });
    }

    return {
      dataDaDocSoc,
      dataADocSoc,
      idTipoDocSoc,
      permessiDocSoc,
      setDataDaDocSoc,
      setDataADocSoc,
      setIdTipoDocSoc,
      setPermessiDocSoc,
      societa_tipo_documenti,
      format,
      loaded: computed(() =>
        store.getters.getStateFromName("loadeddocumenti_societa_list")
      ),
    };
  },
});
</script>

<style></style>
