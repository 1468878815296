<template>
  <div>
    <FiltriDocSoc
      @getDocumentiSocieta="searchDocSocList"
      @resetFilters="resetFilters"
    />
    <TableDocSoc
      @getDocumentiSocieta="getDocumentiSocieta"
      @resetFilters="resetFilters"
      :societa="societa"
    />
  </div>
</template>

<script>
import { defineComponent, onMounted, computed, getCurrentInstance } from "vue";
import { useStore } from "vuex";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import FiltriDocSoc from "@/components/components-fit/società/documenti/FiltriDocSoc.vue";
import TableDocSoc from "@/components/components-fit/società/documenti/TableDocSoc.vue";

export default defineComponent({
  name: "documenti-societa",
  components: {
    FiltriDocSoc,
    TableDocSoc,
  },
  props: {
    societa: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Documenti Societa", [
        "Lista Societa",
        "Dettaglio Societa",
      ]);
    });
    const store = useStore();

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    const stagione = computed(() => store.getters.stagioneSelected);
    const rowsToSkip = computed(() => store.getters.rowsToSkipDocSoc);
    const fetchRows = computed(() => store.getters.fetchRowsDocSoc);
    const sortColumn = computed(() => store.getters.sortColumnDocSoc);
    const sortOrder = computed(() => store.getters.sortOrderDocSoc);

    const data_da = computed(() => store.getters.dataDaDocSoc);
    const data_a = computed(() => store.getters.dataADocSoc);
    const id_tipo = computed(() => store.getters.idTipoDocSoc);
    const acl = computed(() => store.getters.permessiDocSoc);

    const getDocumentiSocieta = () => {
      store.dispatch("setStoreListData", {
        keys: {
          id_societa: props.societa.id,
          anno: stagione.value,
          data_da: data_da.value,
          data_a: data_a.value,
          id_tipo: id_tipo.value,
          acl: acl.value,
          rowsToSkip: rowsToSkip.value,
          fetchRows: fetchRows.value,
          sortColumn: sortColumn.value,
          sortOrder: sortOrder.value,
        },
        apiLink: globalApi.DOCUMENTI_SOCIETA,
        itemName: "documenti_societa_list",
      });
    };
    getDocumentiSocieta();

    const resetFilters = () => {
      store.commit("resetFiltersDocSoc");
      getDocumentiSocieta();
    };
    getDocumentiSocieta();

    const loaded = computed(() =>
      store.getters.getStateFromName("loadeddocumenti_societa_list")
    );

    const searchDocSocList = () => {
      if (!loaded.value) return;
      store.commit("setRowsToSkipDocSoc");
      getDocumentiSocieta();
    };

    return {
      getDocumentiSocieta,
      resetFilters,
      searchDocSocList,
    };
  },
});
</script>
