<template>
  <div class="d-flex">
    <p class="me-2 align-self-end">Visualizza documento in archivio:</p>
    <div @click="$emit('downloadFile')" :style="'cursor: pointer'" v-if="name">
      <img
        :src="name"
        :alt="name"
        :title="name"
        v-if="
          name.toLowerCase().includes('.img') ||
          name.toLowerCase().includes('.png') ||
          name.toLowerCase().includes('.jpeg')
        "
        style="width: 3rem"
      />
      <img
        src="/media/fit-media/PDF.png"
        :alt="name"
        :title="name"
        v-else-if="name.toLowerCase().includes('.pdf')"
        style="width: 3rem"
      />
      <img
        src="/media/fit-media/DOC.png"
        :alt="name"
        :title="name"
        v-else-if="
          name.toLowerCase().includes('.doc') ||
          name.toLowerCase().includes('.docx')
        "
        style="width: 3rem"
      />
      <img
        src="/media/fit-media/pptx_icon.svg"
        :alt="name"
        :title="name"
        v-else-if="
          name.toLowerCase().includes('.ppt') ||
          name.toLowerCase().includes('.pptx')
        "
        style="width: 3rem"
      />
      <img
        src="/media/fit-media/icona_xls.svg"
        :alt="name"
        :title="name"
        v-else-if="
          name.toLowerCase().includes('.xls') ||
          name.toLowerCase().includes('.xlsx')
        "
        style="width: 3rem"
      />
      <img
        src="/media/fit-media/icona_zip.svg"
        :alt="name"
        :title="name"
        v-else-if="name.toLowerCase().includes('.zip')"
        style="width: 3rem"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: "file-preview-upload",
  emits: ["downloadFile", "removeFile"],
  props: {
    name: { type: String, required: true },
    tag: { type: String, default: "li" },
    noText: { type: Boolean, default: false },
  },
};
</script>
