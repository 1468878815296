<template>
  <input id="input_modal" v-model="date" class="d-none" />
  <Datepicker
    v-model="date"
    @update:modelValue="$event ? (date = fromUTCtoISODate($event)) : ''"
    inputClassName="h-28"
    autoApply
    placeholder="Data di approvazione"
    :monthChangeOnScroll="false"
    :enableTimePicker="false"
    :format="format"
    :text-input="{
      format: 'dd.mm.yyyy',
    }"
  />
</template>

<script setup>
import { ref, watch } from "vue";
import Datepicker from "vue3-date-time-picker";
import "vue3-date-time-picker/dist/main.css"; // Assicurati di importare lo stile CSS del plugin
import { format, fromUTCtoISODate } from "@/composables/formatDate";

const date = ref(null);
watch(date, () => {
  const el = document.getElementById("datePickerModuliSoc");
  el.value = date.value;
});
</script>
